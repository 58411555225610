<template>
  <div class="shop-bank-details">
    <b-card v-if="templateInfo && templateInfo.length > 0">
      <b-row>
        <b-col
          v-for="(item, index) of templateInfo"
          :key="index"
          cols="12"
          md="6"
        >
          <b-form-group :label="item.label">
            <b-form-input
              v-model="item.bankdetails.keyvalue"
              :class="{ 'is-invalid': !item.bankdetails.keyvalue && isDataSubmitted }"
              type="text"
            />
          </b-form-group>
        </b-col>
        <div class="col-md-12 mt-3 text-center">
          <button
            class="btn btn-primary"
            @click="saveBankDetails"
          >
            {{ $t('Save') }}
          </button>
        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormGroup, BFormInput } from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useStoreUi } from './useStore'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      templateInfo: [],
      isDataSubmitted: false,
    }
  },
  mounted() {
    this.getBankDetails()
  },
  methods: {
    saveBankDetails() {
      this.isDataSubmitted = true
      setTimeout(() => {
        if (window.SuperApp.validations.anyErrorInModal(false)) {
          return
        }
        this.isDataSubmitted = false
        const { updateBankDetails } = useStoreUi()
        const formData = new FormData()
        this.templateInfo.forEach((x, i) => {
          formData.append(`bankform_id[${i}]`, this.templateInfo[i].id)
          formData.append(`keyvalue[${i}]`, this.templateInfo[i].bankdetails.keyvalue)
          if (this.templateInfo[i].bankdetails.id) {
            formData.append(`id[${i}]`, this.templateInfo[i].bankdetails.id)
          }
        })
        showLoader()
        updateBankDetails(formData)
          .then(({ data }) => {
            if (data.statusCode === '200') {
              showSuccessNotification(this, data.message)
              this.getStoreInfo()
            } else {
              showDangerNotification(this, data.message)
            }
            hideLoader()
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
      })
    },
    getBankDetails() {
      showLoader()
      const { bankTemplates } = useStoreUi()

      bankTemplates()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.templateInfo = data.responseData
            this.templateInfo.forEach(item => {
              if (!item.bankdetails) {
                // eslint-disable-next-line no-param-reassign
                item.bankdetails = {
                  keyvalue: '',
                  id: '',
                }
              }
            })
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getStoreInfo() {
      const { storeProfile } = useStoreUi()

      storeProfile(window.SuperApp.getters.userInfo().id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.storeDetails = data.responseData
            window.SuperApp.getters.setUserInfo(JSON.stringify(this.storeDetails))
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>
